import React from "react"
import { css } from "@emotion/core"

const HomeSectionHeader = ({ children }) => {

  return (
    <div
      className={'fx-3d comic-section-header'}
      css={css`
        text-align: center;
        font-family: Work Sans, sans-serif;
        font-weight: 800;
        line-height: 0.8em;
        color: white;
        text-transform: uppercase;
        margin: 0;
        
        @media screen and (min-width: 480px) {
          font-size: 32px;
        }
        @media screen and (min-width: 740px) {
          font-size: 48px;
        }
        @media screen and (min-width: 960px) {
          font-size: 64px;
        }
        font-size: 28px;`
      }
    >
      <span>{children}</span>
    </div>
  )
}

export default HomeSectionHeader
