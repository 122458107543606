// libs
import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import _ from 'lodash'

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Quote from "../components/quote"
import HomeSection from "../components/home-section"
import ComicItemsList from "../components/comic-items-list"
import { AvailableOn } from "../components/available-on"
import ComicPromoList from "../components/comic-promo-list"
import HomeSectionHeader from "../components/headers/home-section-header"
import HomeSectionSubheader from "../components/headers/home-section-subheader"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const publishedComics = data.publishedComics.edges
    const unpublishedComics = data.unpublishedComics.edges
    const allComics = _.sortBy([...publishedComics, ...unpublishedComics], 'node.frontmatter.order')

    return (
      <Layout location={this.props.location} bg={false} className={"home"}>
        <SEO />

        {/* Home Section */}
        <HomeSection svg={data.svg1.publicURL}>

          {/* Main site title / header */}
          <HomeSectionHeader>Comics & Graphic Novels</HomeSectionHeader>

          <HomeSectionSubheader>about Conspiracy Theories and the Occult</HomeSectionSubheader>

          {/* Main list of comics */}
          <ComicPromoList series={allComics} />

        </HomeSection>

        {/* Home Section */}
        <HomeSection image={data.bg20.childImageSharp.fluid}>
          <div css={css`
              text-align: center;
              margin-bottom:30px;
              `}>
            <HomeSectionHeader>Latest Issues</HomeSectionHeader>
            <HomeSectionSubheader>read now for free!</HomeSectionSubheader>

          </div>

          <ComicItemsList items={data.allSeriesIssues.edges} title={""}/>
        </HomeSection>

        <HomeSection svg={data.svg1.publicURL}>
          <HomeSectionHeader>Also Available On</HomeSectionHeader>
          <AvailableOn music comics/>
        </HomeSection>

        <HomeSection image={data.bg16.childImageSharp.fluid}>

          <div style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
          }}>

            <Quote
              author={"Images and Nerds"}
              quote={"Well researched and well written"}/>

            <Quote
              author={"Geek Syndicate"}
              quote={"stellar job… artwork is glorious"}/>

            <Quote
              author={"Comic Reviews Outside the Cube"}
              quote={"coolest time traveling duo in this genre or any other"}/>

            <Quote
              author={"GeekTheNews.com"}
              quote={"books like this keep me interested in the comic medium"}/>

          </div>
        </HomeSection>

      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
    query {

        bg16: file(relativePath: { eq: "bg16.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth:1280) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        bg20: file(relativePath: { eq: "bg20.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth:960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        svg1: file(relativePath: { eq: "svg1.svg" }) {
            publicURL
        }

        # Published Comics Series
        publishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}, published: {ne: false}}}, sort: {fields: frontmatter___order, order: ASC}) {
            edges {
                node {
                    id
                    fields {slug }
                    ...ComicSeriesFrontmatter
                }
            }
        }

        # Unpublished Comics Series
        unpublishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}, published: {eq: false}}}, sort: {fields: frontmatter___order, order: ASC}) {
            edges {
                node {
                    id
                    fields {slug }
                    ...ComicSeriesFrontmatter
                }
            }
        }

        # Comic Series Issues
        allSeriesIssues: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}}}, sort: {fields: frontmatter___date, order: ASC}) {
            edges {
                node {
                    id
                    html
                    fields {
                        slug
                    }
                    ...ComicIssueFrontmatter
                }
            }
        }

    }
`
