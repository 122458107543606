import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { css } from "@emotion/core"

export const AvailableOn = ({ comics, music, reviews }) => {

  const { spotifyLogo, amazonMusicLogo, comixologyLogo, driveThruComicsLogo, indyplanetLogo, etsyLogo, itunesLogo, pandoraLogo, goodreadsLogo, cbrLogo, redditComicBooksLogo } = useStaticQuery(
    graphql`
        query {
            comixologyLogo: file(relativePath: { eq: "comixology.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            driveThruComicsLogo: file(relativePath: { eq: "drive-thru-comics.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            indyplanetLogo: file(relativePath: { eq: "indyplanet.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            etsyLogo: file(relativePath: { eq: "etsy.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            spotifyLogo: file(relativePath: { eq: "Spotify_Logo_RGB_Black.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            amazonMusicLogo: file(relativePath: { eq: "amazon-music.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            itunesLogo: file(relativePath: { eq: "itunes.png" }) {
                childImageSharp { 
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            pandoraLogo: file(relativePath: { eq: "pandora.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            goodreadsLogo: file(relativePath: { eq: "goodreads.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            redditComicBooksLogo: file(relativePath: { eq: "reddit.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            
            cbrLogo: file(relativePath: { eq: "cbr.png" }) {
                childImageSharp {
                    fluid(maxHeight:60) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `,
  )

  return (
    <div css={css`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 60px;
  
  div {
    max-width: 300px;
    max-height: 60px;
    margin-bottom:40px;
        
    a {
      border: none;
      min-width: 300px;
      display:block;
      img {
       max-width: 300px;
       max-height: 60px;
       object-fit: contain !important;
      }
    }
  
  }
 
`}>

      {comics && <div>
        <OutboundLink target={'_blank'} href={"https://www.comixology.com/Paranoid-American-Inc/comics-publisher/467-0"}>
          <Img fluid={comixologyLogo.childImageSharp.fluid} alt={'comixology'}/>
        </OutboundLink>
      </div>}

      {comics && <div>
        <OutboundLink target={'_blank'} href={"https://www.drivethrucomics.com/browse/pub/4936/Paranoid-American-Inc"}>
          <Img fluid={driveThruComicsLogo.childImageSharp.fluid} alt={'drivethrucomics'}/>
        </OutboundLink>
      </div>}

      {comics && <div>
        <OutboundLink target={'_blank'} href={"https://indyplanet.com/time-samplers-1"}>
          <Img fluid={indyplanetLogo.childImageSharp.fluid} alt={'indyplanet'}/>
        </OutboundLink>
      </div>}

      {comics && <div>
        <OutboundLink target={'_blank'} href={"https://paranoidamerican.etsy.com"}>
          <Img fluid={etsyLogo.childImageSharp.fluid} alt={'etsy'}/>
        </OutboundLink>
      </div>}

      {music && <div>
        <OutboundLink target={'_blank'} href={"https://itunes.apple.com/us/artist/sound-scientists/386387248"}>
          <Img fluid={itunesLogo.childImageSharp.fluid} alt={'itunes'}/>
        </OutboundLink>
      </div>}

      {music && <div>
        <OutboundLink target={'_blank'} href={"https://amzn.to/36YFMxy"}>
          <Img fluid={amazonMusicLogo.childImageSharp.fluid} alt={'amazon'}/>
        </OutboundLink>
      </div>}

      {music && <div>
        <OutboundLink target={'_blank'} href={"https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ"}>
          <Img fluid={spotifyLogo.childImageSharp.fluid} alt={'spotify'}/>
        </OutboundLink>
      </div>}

      {music && <div>
        <OutboundLink target={'_blank'} href={"https://www.pandora.com/artist/sound-scientists/ARf2bK63gd6rJvk"}>
          <Img fluid={pandoraLogo.childImageSharp.fluid} alt={'pandora'}/>
        </OutboundLink>
      </div>}

      {reviews && <div>
        <OutboundLink target={'_blank'} href={"https://www.goodreads.com/book/show/17210054-time-samplers-1---escape-from-jekyll-island"}>
          <Img fluid={goodreadsLogo.childImageSharp.fluid} alt={'goodreads'}/>
        </OutboundLink>
      </div>}

      {reviews && <div>
        <OutboundLink target={'_blank'} href={"https://www.reddit.com/r/comicbooks/"}>
          <Img fluid={redditComicBooksLogo.childImageSharp.fluid} alt={'reddit'}/>
        </OutboundLink>
      </div>}

      {reviews && <div>
        <OutboundLink target={'_blank'} href={"https://community.cbr.com/"} alt={'cbr'}>
          <Img fluid={cbrLogo.childImageSharp.fluid}/>
        </OutboundLink>
      </div>}

    </div>
  )
}