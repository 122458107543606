import React from "react"
import { css } from "@emotion/core"
import SectionHeader from "./headers/section-header"

const Quote = ({ author, quote, link }) => {
	return (
		<div css={css`
				text-align: center;
				display: flex;
				flex: 1;
				flex-direction: column;
				margin: 30px;
		`}>
			<SectionHeader>{author}</SectionHeader>
			<div css={css`
					font-family: Work Sans, arial, sans-serif;
					font-weight: 800;
					text-transform: uppercase;
					line-height: 0.85em;
					letter-spacing: -0.03em;
					position:relative;
					@media screen and (min-width: 480px) {
            font-size: 48px;
          }
          @media screen and (min-width: 740px) {
            font-size: 56px;
          }
          @media screen and (min-width: 960px) {
            font-size: 60px;
          }
          font-size: 42px;
			`} className={'fx-3d'}>“{quote}”</div>
		</div>
	)
}

export default Quote
