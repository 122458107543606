import React from "react"
import { css } from "@emotion/core"

const HomeSectionSubheader = ({ children }) => {

  return (
    <div
      css={css`

        color:white;                        
        text-align: center;
        font-family: 'Work Sans', arial, sans-serif;
        font-weight:400;
        font-size: 14px;
        padding:0;
        line-height:0.8em;
        text-transform: uppercase;
        transition: font-size 0.2s;
        
        margin: 10px 0 0 0;
        @media screen and (min-width: 480px) {
          font-size: 18px;
        }
        @media screen and (min-width: 740px) {
          font-size: 24px;
          padding: 3px 6px;
          line-height:1.5em;
        }
        @media screen and (min-width: 960px) {
          font-size: 32px;
          padding: 10px;
          line-height:1.5em;
          margin: 0;
        }

        span {
          background-color: #101010;
          display:inline;
          box-decoration-break: clone; 
          padding: 4px 12px 6px;
          line-height:1.5em;
        }
`
      }
    >
      <span>{children}</span>
    </div>
  )
}

export default HomeSectionSubheader
