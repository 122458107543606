import React from "react"
import { css } from "@emotion/core"
import Color from 'color'
import Img from "gatsby-image"

const HomeSection = ({ color = '#686868', children, image, svg }) => {
  return (
  	// if valid image was supplied, use is as the background
    image ?
      <div
        css={css`
          > * { position:relative; }
          position: relative;
					color: #ffffff;					
				padding: 20px 5px;
					@media screen and (min-width: 480px) {
            padding: 20px 14px;
          }
          @media screen and (min-width: 740px) {
            padding: 20px 20px 40px;
          }
          @media screen and (min-width: 1240px) {
            padding: 30px 0 60px;
          }
				`}
      >

        <Img
          alt={'background'}
          fluid={image}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}
        />

        <div
          css={css`
					margin: 0 auto;
					max-width: 1200px;
				`}
        >
          {children}
        </div>
      </div>
      :
      <div
        css={css`
        background: url(${svg}), ${color};
        background-size: cover;
				color: ${Color(color).luminosity() < .4 ? "white" : "inherit"};
				padding: 20px 5px;
					@media screen and (min-width: 480px) {
            padding: 20px 14px;
          }
          @media screen and (min-width: 740px) {
            padding: 20px 20px 40px;
          }
          @media screen and (min-width: 1240px) {
            padding: 30px 0 60px;
          }
			`}
      >
        <div
          css={css`
					margin: 0 auto;
					max-width: 1200px;
				`}
        >
          {children}
        </div>
      </div>
  )
}

export default HomeSection
